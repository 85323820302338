'use client'

import { useEffect, useRef } from 'react'
import { Readex_Pro } from 'next/font/google'
import { ToastContainer, toast } from 'react-toastify'
import { usePathname } from 'next/navigation'
import './globals.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'

// Initialize the Readex Pro font
const readexPro = Readex_Pro({
  weight: ['300', '400', '500', '600', '700'], // Customize weights as needed
  subsets: ['latin'], // Choose the subset that you need
})

export default function RootLayout({ children }) {
  const ToastContainerRef = useRef(ToastContainer)
  const pathname = usePathname()

  useEffect(() => {
    toast.dismiss()
  }, [pathname])

  return (
    <html lang="en">
      <body className={readexPro.className}>
        {children}
        <ToastContainerRef.current />
      </body>
    </html>
  )
}
